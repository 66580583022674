import React, { useCallback, useEffect, useState, Suspense } from 'react';
import { useRouter } from 'next/router';
import { useStore } from '@/zustand/store/store';
import TaskCenterCard2 from './_taskCard2';
import styles from './TaskCenterCardList.module.scss';
import TaskDetailModal from './TaskDetailModal';
import { ITaskDataType } from '@/types/Achievements';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';

export interface TaskCenterCardListProps {
  items: Array<ITaskDataType>;
  role?: boolean;
  isHome?: boolean;
}

export const normalKey = 'taskId';
export const specificKey = 'comander-taskId';
export const onBoardingKey = 'isonboarding';

const TaskCenterCardList2: React.FunctionComponent<TaskCenterCardListProps> = ({ items, role, isHome }) => {
  const [selectedTaskId, setSelectedTaskId] = useState<{ id: string; role?: boolean } | null>(null);
  const { push, query } = useRouter();
  const { setCommandCenter, setIsShowToast, setOnBoardingData, onBoardingData } = useStore();
  const { isLogin } = useAuthStatuses();
  const [isOnboarding, setIsOnboarding] = useState(false);

  useEffect(() => {
    setIsOnboarding(query.isonboarding === undefined);
  }, [query.isonboarding]);

  // Memoized handler to handle task click
  const handleClickTask = useCallback(
    (name: string, id: string, lock: boolean) => {
      if (lock) {
        setIsShowToast({
          isShow: true,
          message: 'This command is currently locked and unavailable, but it will be accessible in the future.',
          method: 'info',
        });
        return;
      }

      const newQuery = { ...query };
      delete newQuery[normalKey];
      delete newQuery[specificKey];
      delete newQuery[onBoardingKey];

      setCommandCenter({ metaTitle: name });
      setSelectedTaskId({ id, role });

      push(
        {
          pathname: '/command-center',
          query: { ...newQuery, [role ? specificKey : normalKey]: id },
        },
        undefined,
        { shallow: true },
      );
    },
    [query, role, setCommandCenter, setIsShowToast, push],
  );

  const handleCloseModal = useCallback(() => {
    setOnBoardingData({ ...onBoardingData, isShowHint: false, isShowHintClaimButton: false });
    setSelectedTaskId(null);

    const newQuery = { ...query };
    delete newQuery[normalKey];
    delete newQuery[specificKey];
    delete newQuery[onBoardingKey];

    push({ pathname: '/command-center', query: newQuery }, undefined, { shallow: true });
  }, [query, onBoardingData, setOnBoardingData, push]);

  useEffect(() => {
    if (query[normalKey]) {
      setSelectedTaskId({ id: query[normalKey] as string, role: false });
    } else if (query[specificKey]) {
      setSelectedTaskId({ id: query[specificKey] as string, role: true });
    } else {
      setSelectedTaskId(null);
    }
  }, [query]);

  return (
    <>
      {isHome ? (
        <div className={styles.homeCardsWrapper}>
          <div className={styles.homeCards}>
            {items.map((item, index) => (
              // @ts-ignore todo add color to item type
              <div className='min-w-[250px] w-full max-h-[400px]' key={item?.id}>
                <TaskCenterCard2
                  className='w-full'
                  role={role}
                  idItem={`step${index}`}
                  {...item}
                  bg_image={item?.img ? `${process.env.NEXT_PUBLIC_IMAGE_CDN3}/${item.img}` : '/images/default-command.jpg'}
                  onClick={() => handleClickTask(item.name, item.id, item.lock)} // using `item.name` instead of `item.title`
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          {items.map((item, index) => (
            // @ts-ignore todo add color to item type
            <TaskCenterCard2
              role={role}
              idItem={`step${index}`}
              key={item?.id}
              {...item}
              bg_image={item?.img ? `${process.env.NEXT_PUBLIC_IMAGE_CDN3}/${item.img}` : '/images/default-command.jpg'}
              onClick={() => handleClickTask(item.name, item.id, item.lock)} // using `item.name` instead of `item.title`
            />
          ))}
          {selectedTaskId && (
            <Suspense fallback={<div>Loading...</div>}>
              <TaskDetailModal role={selectedTaskId.role} taskId={selectedTaskId.id} onClose={handleCloseModal} />
            </Suspense>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(TaskCenterCardList2);
