import { useRef, useState } from 'react';
import { SelectItem, SelectProps } from '@mantine/core';
import { Typography } from '@/components/Atoms';
import TaskArrowDown from '@/components/Svg/TaskArrowDown';
import { useOutsideModal } from '@/hooks/useOutsideModal';
import styles from './SelectBox.module.scss';

interface SelectBoxProps {
  items: SelectItem[];
  value: SelectProps['value'];
  onChange?: SelectProps['onChange'];
}

const placeholder = 'Choose Your Commander';

const SelectBox: React.FunctionComponent<SelectBoxProps> = ({ items, value, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  useOutsideModal(ref, () => setIsOpen(false));

  const handleChange = (newValue: SelectProps['value']) => {
    setIsOpen(false);
    onChange?.(newValue);
  };

  const selectedItem = items?.find((item) => item.value === value);

  return (
    <div className='relative' ref={ref}>
      <div className='flex' onClick={() => setIsOpen(!isOpen)}>
        <div className='border w-10 h-10 border-solid border-[#00F0FF] bg-[#0A50547A] flex justify-center items-center cursor-pointer rounded-md'>
          {isOpen ? <TaskArrowDown className='transform rotate-180' /> : <TaskArrowDown />}
        </div>
        <div className='border text-sm  h-10 min-w-32 w-fit border-solid border-[#00F0FF] bg-[#0A50547A] flex justify-center items-center cursor-pointer py-[11px] px-[10px] rounded-md'>
          {selectedItem ? (
            <Typography className='text-white' size='s14ToS12'>
              {selectedItem.label}
            </Typography>
          ) : (
            <Typography className='text-gray-300' size='s12ToS12'>
              {placeholder}
            </Typography>
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.itemsContainer}>
          <Typography size='s12ToS12' className='text-[#9E9E9E] select-none'>
            {placeholder}
          </Typography>
          <div className='flex flex-col gap-1.5'>
            {(items as SelectItem[])?.map((item) => (
              <div
                key={item.value}
                className={`${item.value === value ? 'text-[#31DA60]' : 'text-white'} flex items-center gap-2 cursor-pointer w-fit`}
                onClick={() => handleChange(item.value)}
              >
                <span className={`w-[3px] h-[3px] rounded-full ${item.value === value ? 'bg-[#31DA60]' : 'bg-white'}`} />
                <Typography size='s12ToS12'>{item.label}</Typography>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectBox;
