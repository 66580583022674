/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import base64 from 'base-64';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
// eslint-disable-next-line import/no-extraneous-dependencies
import LZString from 'lz-string';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useAccount } from 'wagmi';
import api from '@/api/services/achievements';
import inventoryApi from '@/api/services/inventory';
import { ITaskActionDataType } from '@/types/Achievements';
import { EAuthVarient } from '@/types/Authentication';
import { OtherAccountsResponseType } from '@/types/Inventory';
import { useStore } from '@/zustand/store/store';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import useConnectWallet from '@/hooks/common/useConnectWallet';
import useTweeter from '@/hooks/Achievements/useTweeter';
import useTelegram from '@/hooks/Achievements/useTelegram';
import useDiscord from '@/hooks/Achievements/useDiscord';

export type IProps = ITaskActionDataType & {
  mutate: () => void;
  closeModal?: () => void;
  referal_user_id?: string;
  onDoneCallback?: () => void;
  achievementId?: string;
  tweetId?: string;
  isLocked: boolean;
};

const botAddress = 'https://t.me/test_referral_22222_bot';

const compressAndEncode = (data: { userid: string; actionid: string; achivimentid: string }) => {
  const compressed = LZString.compressToUint8Array(`${data.userid}&${data.actionid}&${data.achivimentid}`);
  return base64.encode(String.fromCharCode.apply(null, compressed));
};

export const useActionHandler = ({ mutate, closeModal, tweetId, ...taskDetails }: IProps) => {
  const { isLogin } = useAuthStatuses();
  const { setInviteFriendModal, setAuthModalVarient, setLiteGameId, setEarlyAccessModal } = useStore();
  const [otherAccounts, setOtherAccounts] = useState<OtherAccountsResponseType | null>(null);
  const router = useRouter();
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleClickWallet } = useConnectWallet();
  const session = useSession();
  const { address } = useAccount();
  const getUserOtherAccounts = () => {
    inventoryApi.checkOtherAccount().then((res) => {
      setOtherAccounts(res.data);
    });
  };

  const actionId = taskDetails.id;
  useEffect(() => {
    if (isLogin) {
      getUserOtherAccounts();
    }
  }, [actionId, isLogin]);

  const { startTweetFlow } = useTweeter(
    taskDetails,
    actionId as string,
    Boolean(otherAccounts?.data?.find((el) => el.AccountType === 'twitter')),
    // otherAccounts?.data?.find((el) => el.AccountType === 'twitter')?.ScreenName,
    tweetId,
  );
  const { startTelegramFlow } = useTelegram(
    { ...taskDetails, userId: token },
    Boolean(otherAccounts?.data?.find((el) => el.AccountType === 'telegram')),
  );
  const { startDiscordFlow } = useDiscord(taskDetails, Boolean(otherAccounts?.data?.find((el) => el.AccountType === 'discord')));

  useEffect(() => {
    if (session.data !== undefined && session.data !== null && session.status !== 'loading') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const decodedAccessToken: { exp: any } = jwt_decode(session.data.accessToken).token;

      setToken(decodedAccessToken);
    }
  }, [session]);

  const isTaskLocked =
    (taskDetails?.start_time !== 0 && taskDetails?.start_time > new Date().getTime() / 1000) ||
    (taskDetails?.end_time !== 0 && taskDetails?.end_time < new Date().getTime() / 1000);

  const validateTaskRestriction = async (id: string) => {
    if (!taskDetails || isTaskLocked) {
      return;
    }

    try {
      const resData = await api.getUserTaskRestriction(id);

      setIsLoading(true);

      if (resData.status) {
        toast.success('Thank you for participating! Stay tuned for the next set of tasks and missions.', {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          position: 'top-center',
          autoClose: 2000,
          closeOnClick: true,
          theme: 'dark',
        });
        setTimeout(() => {
          router.push('/lite-game?mode=Play-to-Earn');
        }, 2000);
      } else {
        toast.error('This command is not applicable to you, and participation is restricted.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#5a0000',
            color: 'white',
          },
        });
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        toast.error('This task is currently deactivated due to time constraints or reaching the maximum number of participants.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#5a0000',
            color: 'white',
          },
        });
      } else if (err?.response?.status === 404) {
        toast.error('This command is not applicable to you, and participation is restricted.', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#5a0000',
            color: 'white',
          },
        });
      } else {
        toast.error('something went wrong!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#5a0000',
            color: 'white',
          },
        });
      }
    } finally {
      setIsLoading(false);
      mutate();
    }
  };

  const activityOnClick = async () => {
    if (!isLogin && taskDetails?.action_type.Name == 'signup') {
      // closeModal();
      Cookies.set('isSignUpTask', 'true');
      Cookies.set('achievementId', taskDetails?.achievementId);
      Cookies.set('actionId', taskDetails?.id);
      Cookies.set('additionalData', taskDetails?.additional_data);
      setAuthModalVarient(EAuthVarient.SIGN_UP, `/command-center?taskId=${taskDetails.achievementId}`);
      return;
    }
    if (!isLogin) {
      // closeModal();
      return setAuthModalVarient(EAuthVarient.SIGN_IN);
    }
    if (!taskDetails || isTaskLocked) {
      return;
    }

    switch (taskDetails?.action_type.Name) {
      case 'signup':
        // api
        //   .doneTask({
        //     id: taskDetails?.id,
        //     data: taskDetails?.additional_data,
        //     referal_user_id: taskDetails?.referal_user_id,
        //     achievement_id: taskDetails.achievementId,
        //   })
        //   .then((res) => {
        //     if (!res.data.error) {
        //       // closeModal();
        //     } else {
        //       toast.error(res.data.error, {
        //         position: 'top-center',
        //         autoClose: 5000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         style: {
        //           background: '#5a0000',
        //           color: 'white',
        //         },
        //       });
        //     }
        //   });
        break;
      case 'Game':
      case 'game':
      case 'win_game':
        router.push('/game');
        break;

      case 'telegram_earlyaccess':
      case 'telegram_submited_user':
        api
          .doneTask({
            id: taskDetails?.id,
            data: taskDetails?.additional_data,
            referal_user_id: taskDetails?.referal_user_id,
            achievement_id: taskDetails.achievementId,
          })
          .then((res) => {
            if (!res.data.error) {
              closeModal();
              setEarlyAccessModal(true);
            } else {
              toast.error(res.data.error, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  background: '#5a0000',
                  color: 'white',
                },
              });
            }
          })
          .catch((error) => {
            toast.error(error.response.data.error, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: '#5a0000',
                color: 'white',
              },
            });
          });

        break;
      case 'win_lite':
        const additional_dataaa = JSON.parse(taskDetails?.additional_data);
        if (!additional_dataaa?.link) {
          return;
        } else {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          window.open(`${additional_dataaa?.link}&commandId=${taskDetails?.achievementId}`, '_blank', 'noopener noreferrer');
        }

        break;
      case 'mining':
        const additional_dataa = JSON.parse(taskDetails?.additional_data);
        if (!additional_dataa?.url) {
          return;
        } else {
          api
            .doneTask({
              id: taskDetails?.id,
              data: taskDetails?.additional_data,
              referal_user_id: taskDetails?.referal_user_id,
              achievement_id: taskDetails.achievementId,
            })
            .then((res) => {
              if (!res.data.error) {
                closeModal();
                window.open(`${additional_dataa?.url}&commandId=${taskDetails?.achievementId}`, '_blank', 'noopener noreferrer');
              } else {
                toast.error(res.data.error, {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  style: {
                    background: '#5a0000',
                    color: 'white',
                  },
                });
              }
            });
        }
        break;

      case 'staking':
        if (address) {
          api
            .doneTask({
              id: taskDetails?.id,
              data: taskDetails?.additional_data,
              referal_user_id: taskDetails?.referal_user_id,
              achievement_id: taskDetails.achievementId,
            })
            .then((res) => {
              if (!res.data.error) {
                closeModal();
                router.push('/stake');
              } else {
                toast.error(res.data.error, {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  style: {
                    background: '#5a0000',
                    color: 'white',
                  },
                });
              }
            });
        } else {
          handleClickWallet();
        }
        break;
      case 'Discord':
        startDiscordFlow();
        break;
      case 'telegram_join_group':
      case 'telegram_join_channel':
      case 'referral_telegram':
      case 'network_telegram':
        startTelegramFlow();
        break;
      case 'referral_play_game':
      case 'referral_signup':
        api
          .doneTask({
            id: taskDetails?.id,
            data: taskDetails?.additional_data,
            referal_user_id: taskDetails?.referal_user_id,
            achievement_id: taskDetails.achievementId,
          })
          .then((res) => {
            if (!res.data.error) {
              closeModal();
              setInviteFriendModal(true);
            } else {
              toast.error(res.data.error, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  background: '#5a0000',
                  color: 'white',
                },
              });
            }
          });
        break;
      case 'twitter_follow':
      case 'twitter_like':
      case 'twitter_retweet':
      case 'twitter_tweet':
        startTweetFlow();
        break;
      case 'Common':
      case 'Rare':
      case 'Epic':
      case 'Legendary':
        validateTaskRestriction(taskDetails?.id);
        break;
      case 'visit_link':
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const additional_data = JSON.parse(taskDetails?.additional_data);
        if (!additional_data?.link) {
          return;
        }
        try {
          await api.doneTask({
            id: taskDetails?.id,
            data: taskDetails?.additional_data,
            referal_user_id: taskDetails?.referal_user_id,
            achievement_id: taskDetails.achievementId,
          });
          if (!(additional_data?.link as string).startsWith('#')) {
            window.open(additional_data?.link, '_blank', 'noopener noreferrer');
          }
        } catch (error) {
          const errorMessage = error?.response?.data?.error || 'Something went wrong! Please try again later.';
          toast.error(errorMessage, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#5a0000',
              color: 'white',
            },
          });
        }
        mutate();
        break;
      case 'craft':
        if (!address) {
          toast.info('You need to connect your wallet before start command', {
            position: 'top-center',
            autoClose: 2000,
            closeOnClick: true,
            theme: 'dark',
          });
          handleClickWallet();
        } else {
          api
            .doneTask({
              id: taskDetails?.id,
              data: taskDetails?.additional_data,
              referal_user_id: taskDetails?.referal_user_id,
              achievement_id: taskDetails.achievementId,
            })
            .then((res) => {
              if (!res.data.error) {
                // closeModal();
              } else {
                toast.error(res.data.error, {
                  position: 'top-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  style: {
                    background: '#5a0000',
                    color: 'white',
                  },
                });
              }
            })
            .catch((error) => {
              const errorMessage = error?.response?.data?.error || 'Something went wrong! Please try again later.';

              toast.error(errorMessage, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  background: '#5a0000',
                  color: 'white',
                },
              });
            });
        }
        break;
      default:
        api
          .doneTask({
            id: taskDetails?.id,
            data: taskDetails?.additional_data,
            referal_user_id: taskDetails?.referal_user_id,
            achievement_id: taskDetails.achievementId,
          })
          .then((res) => {
            if (!res.data.error) {
              closeModal();
              setInviteFriendModal(true);
            } else {
              toast.error(res.data.error, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  background: '#5a0000',
                  color: 'white',
                },
              });
            }
          });
    }
  };

  return {
    activityOnClick,
  };
};
