import { useEffect, useState } from 'react';

// Function to convert epoch start and end dates to a readable format
export function getDurationFormatted(startEpoch: number, endEpoch: number, useCustomFormat: boolean): string {
  // Calculate the difference in milliseconds
  const diff = endEpoch - startEpoch;

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  if (days > 0) {
    return days === 1 ? '1 day' : `${days} days`;
  } else if (useCustomFormat) {
    // Format the duration to HH:mm:ss for durations under a day
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  } else {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
}

const useCountdown = (endEpoch: number, useCustomFormat = false) => {
  const [timeLeft, setTimeLeft] = useState<string>('00:00:00');

  useEffect(() => {
    const updateCountdown = () => {
      const now = Date.now();
      const diff = endEpoch - now;

      if (diff <= 0) {
        setTimeLeft('00:00:00');
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        clearInterval(intervalId);
      } else {
        const formattedTime = getDurationFormatted(now, endEpoch, useCustomFormat);
        setTimeLeft(formattedTime);
      }
    };

    updateCountdown(); // Initial call to set the time immediately
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [endEpoch, useCustomFormat]);

  return timeLeft;
};
export const useCountdownDays = (endEpoch: number) => {
  const calculateTimeLeft = () => {
    const now = Date.now();
    const diff = endEpoch - now;

    if (diff <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endEpoch]);

  return timeLeft;
};


export default useCountdown;
