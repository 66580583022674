import { useCallback } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import api from '@/api/services/achievements';
import { ITaskActionDataType } from '@/types/Achievements';
import { useStore } from '@/zustand/store/store';

const useTweeter = (
  item?: ITaskActionDataType & { referal_user_id?: string; achievementId?: string },
  id?: string,
  isVerified?: boolean,
  // userId?: string,
  tweetedId?: string,
) => {
  const { isTweetTaskVerified, setIsTweetTaskVerified } = useStore();

  const tweetId = item?.additional_data && JSON.parse(item?.additional_data)?.id;
  const tweetText = item?.additional_data && JSON.parse(item?.additional_data)?.content;
  const actionName = item?.action_type.Name;
  const Link = 'https://x.com/intent/';
  const accountName = item?.additional_data && JSON.parse(item?.additional_data)?.account_name;

  const handleTwitterLogin = useCallback(async () => {
    try {
      const response = await axios.post('/api/auth/twitter/request-token');
      const { oauth_token: oauthToken } = response.data;
      window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthToken}`;
    } catch (error) {
      console.error('Error during Twitter login:', error);
    }
  }, []);

  const returnLink = () => {
    const encodedTweetText = encodeURIComponent(tweetText);
    switch (actionName) {
      case 'twitter_like':
        return `${Link}like?tweet_id=${tweetId}`;
      case 'twitter_retweet':
        return `${Link}retweet?tweet_id=${tweetId}`;
      case 'twitter_follow':
        return `${Link}follow?screen_name=${accountName}`;
      case 'twitter_tweet':
        return `${Link}tweet?text=${encodedTweetText}`;
      default:
        return null;
    }
  };

  const startTweetFlow = async () => {
    if (!isVerified) {
      handleTwitterLogin();
      return;
    }

    try {
      if (actionName === 'twitter_tweet') {
        if (!tweetedId) {
          toast.error('Invalid URL detected. Please verify the link and try again.', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#5a0000',
              color: 'white',
            },
          });
          return;
        }
        // setRequestModalVisible(true, { taskId: id, link: returnLink(), expireDate: '', additional_data: item.additional_data });
      } else {
        const link = returnLink();
        if (link) {
          window.open(link);
        } else {
          console.error('Failed to generate a valid link for Twitter task.');
        }
      }
      const data = tweetedId && item.action_type.Name === 'twitter_tweet' ? JSON.stringify({ id: tweetedId }) : item?.additional_data;
      await api.doneTask({ id: item?.id, data, referal_user_id: item?.referal_user_id, achievement_id: item.achievementId });
      if (actionName === 'twitter_tweet' && !isTweetTaskVerified.includes(item.id)) {
        setIsTweetTaskVerified([...isTweetTaskVerified, item.id]);
      }
    } catch (error) {
      toast.error('Invalid Referral Code. Please check your referral code and try again.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#5a0000',
          color: 'white',
        },
      });
    }
  };

  return { handleTwitterLogin, returnLink, startTweetFlow };
};

export default useTweeter;
