import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import api from '@/api/services/achievements';
import { Typography } from '@/components/Atoms';
import ArmieItem from './ArmieItem';

export const commanderQueryKey = 'commander';

const ArmiesList = () => {
  const { data, isLoading } = api.useCommandersInfinite();
  const router = useRouter();

  let filteredItems = data?.flatMap((page) => page.data.data) ?? [];
  filteredItems = filteredItems.filter((item) => item);

  let res: ReactNode = null;

  if (isLoading) {
    res = (
      <>
        <ArmieItem loading />
        <ArmieItem loading />
        <ArmieItem loading />
      </>
    );
  }

  if (filteredItems.length > 0) {
    res = filteredItems.map(({ badge, ...item }) => (
      <ArmieItem
        key={item.id}
        title={item.username}
        img={badge.Image}
        value={{ max: item.achievement_count }}
        onClick={() => {
          router.push(
            {
              pathname: router.pathname,
              query: { ...router.query, [commanderQueryKey]: item.id },
            },
            undefined,
            { shallow: true, scroll: false },
          );
        }}
      />
    ));
  } else {
    res = (
      <Typography size='s12ToS12' className='text-white text-center flex justify-center w-full'>
        No Commanders available ATM.
      </Typography>
    );
  }

  return <div className='mt-3 space-y-1'>{res}</div>;
};

export default ArmiesList;
