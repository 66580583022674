import CustomSkeleton from '@/components/Common/CustomSkeleton';

const ContentSectionLoading = () => (
  <>
    <CustomSkeleton height={150} width={250} mx='auto' />
    <CustomSkeleton height={12} mt={12} radius='xl' />
    <CustomSkeleton height={12} mt={6} radius='xl' />
    <CustomSkeleton height={12} mt={6} radius='xl' />
    <CustomSkeleton height={12} mt={6} radius='xl' />
    <CustomSkeleton height={12} mt={6} radius='xl' />
    <CustomSkeleton height={12} mt={6} radius='xl' />
    <CustomSkeleton height={12} mt={6} radius='xl' />
    <CustomSkeleton height={12} mt={6} width='70%' radius='xl' />
  </>
);

export default ContentSectionLoading;
