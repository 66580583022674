import CustomSkeleton from '@/components/Common/CustomSkeleton';

const TaskOverviewLoading = () => (
  <>
    <CustomSkeleton height={150} width='100%' />
    <CustomSkeleton height={12} width='70%' mt={12} mb={14} radius='xl' />
  </>
);

export default TaskOverviewLoading;
