import React from 'react';
import { RingProgress } from '@mantine/core';
import { Progress } from '@material-tailwind/react';
import cn from 'classnames';
import Image from 'next/image';
import { Typography } from '@/components/Atoms';
import TimeLeftSvgTwo from '@/components/Svg/TimeLeftSvgTwo';

interface TaskOverviewProps {
  isLocked: boolean;
  diffDateFormatted: string;
  image: string;
  title: string;
  description?: string;
  progress: number;
  end_time: any;
  winner?: string;
}

export const TaskOverview: React.FunctionComponent<TaskOverviewProps> = ({ isLocked, diffDateFormatted, winner, ...props }) => {
  const myProgress = Math.min(props.progress, 100);

  return (
    <div
      // style={{ backgroundImage: `url(${bg_image})`, backgroundRepeat: 'no-repeat', objectFit: 'cover' }}
      className='relative flex items-end pb-4 ps-0.5 rounded-t-[15px] h-[143px] w-full'
    >
      <div className='absolute top-0 right-0 z-10'>
        {!isLocked && props.end_time > 0 && props.end_time > new Date().getTime() / 1000 && (
          <div
            className='gap-1 rounded-full flex items-center w-fit ms-auto flex-shrink-0 px-2 py-1'
            style={{ backgroundColor: 'rgba(49, 218, 96, 0.2)' }}
          >
            <Typography className='text-[#31DA60]' size='s12ToS12' isBold>
              {diffDateFormatted} Left
            </Typography>
            <TimeLeftSvgTwo />
          </div>
        )}
      </div>
      <Image
        src={props.image}
        alt='image not loaded'
        layout='fill'
        objectFit='cover'
        className={cn('rounded-t-[15px] h-[143px]', isLocked && 'blur-sm')} // Optional: Add any additional styles if needed
      />
      <div
        className='absolute bottom-0 left-0 w-full h-full rounded-t-[15px]'
        style={{ background: ' linear-gradient(0deg, #111217 0%, transparent 100%)' }}
      ></div>

      {isLocked && (
        <>
          <div className='absolute inset-0 bg-gradient-to-b from-[rgba(30,24,38,1)] to-[rgba(17,18,23,0)]'></div>

          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='flex flex-col items-center font-bold bg-gradient-to-r to-[#12DB00] from-[#CEBF38] text-transparent bg-clip-text'>
              <Typography size='s28ToS18'>{diffDateFormatted}</Typography>
              <Typography className='-mt-0.5' size='s20ToS16'>
                To Activation
              </Typography>
            </div>
          </div>
        </>
      )}

      <div className='absolute w-full -bottom-12 flex flex-col gap-2'>
        <div className='flex flex-col'>
          {winner && (
            <Typography size='s26ToS16' className='text-center text-[#FFC83D] font-bold w-full truncate mb-4 xl:mb-1'>
              WINNER: {winner}
            </Typography>
          )}
          {!isLocked && (
            <h1 className='text-white font-bold'>
              <Typography size='s28ToS15' isBold>
                {props.title}
              </Typography>
            </h1>
          )}
          <h1 className='text-[#9597A1] font-semibold'>
            <Typography className='-leading-8' size='s12ToS12'>
              {props.description}
            </Typography>
          </h1>
        </div>

        {/* <RingProgress
          size={65}
          sections={[{ value: myProgress, color: '#31DA60' }]}
          label={
            <div className='text-center'>
              <div className='flex flex-col justify-center items-center'>
                <Typography className='text-[#31DA60] text-[10px] mt-0.5' isBold>
                  Progress
                </Typography>
                <Typography className='text-[#31DA60]' size='s12ToS12'>
                  {myProgress.toFixed()}%
                </Typography>
              </div>
            </div>
          }
          thickness={3}
          rootColor='#1E1E1E'
        /> */}
        {myProgress > 0 && (
          <div className='w-full flex flex-row items-center gap-2'>
            <Progress
              value={myProgress}
              className='border-[#3D3D3D] border bg-[#2B2B2B] rounded-[3px] h-[8px]'
              color='green'
              placeholder={'fd'}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            <div className='flex items-center justify-between gap-4'>
              <Typography size='s12ToS12' className='text-[#31DA60]'>
                {myProgress.toFixed()}%
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskOverview;
