import { Typography } from '@/components/Atoms';
import FrameSvg from '@/components/Svg/FrameSvg';

type IProps = {
  current: number;
  max: number;
};

const UserCounter = (props: IProps) => {
  return (
    <div className='flex items-center gap-2'>
      <Typography size='s12ToS12' className='text-[#9E9E9E]'>
        {`${props.current}/${props.max}`}
      </Typography>
      <FrameSvg />
    </div>
  );
};

export default UserCounter;
