import { Typography } from '@/components/Atoms';

interface ItemHolderProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
}

const ItemHolder: React.FunctionComponent<ItemHolderProps> = ({ title, children, className }) => {
  return (
    <div>
      <span className='flex items-center gap-4 mb-2'>
        <Typography size='s12ToS12' className='text-[#9597A1]'>
          {title}
        </Typography>
        {/* <hr className='h-px block border-t border-solid border-[#434343] flex-1 mt-0.5' /> */}
      </span>
      <div className=''>
      <div className= {className ?? ''}>{children}</div>
      </div>
    </div>
  );
};

export default ItemHolder;
