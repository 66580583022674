/* eslint-disable sonarjs/no-small-switch */
import React, { forwardRef, ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { Tooltip } from '@mantine/core';
import classNames from 'classnames';
import cn from 'classnames';
import { Typography } from '@/components/Atoms';
import LoadingSvg from '@/components/Svg/LoadingSvg';
import { numberSeparator } from '@/utils/numberSeparator';
import { useStore } from '@/zustand/store/store';
import style from '../../TaskDetailModal.module.scss';

export type SubTaskCheckBoxStatus = 'completed' | 'pending' | 'start' | 'not_verified';

interface SubTaskCheckBoxProps {
  status: SubTaskCheckBoxStatus;
  description: string;
  info?: string;
  passive?: boolean;
  isTweet?: boolean;
  tweetLink?: string;
  actionId?: string;
  setTweetLink?: (link: string) => void;
  onClick?: () => void;
  isLocked: boolean;
  count?: number;
  userCount?: number;
  amountType?: string;
  amount?: number;
  userAmount?: number;
  name: string;
}

export interface SubTaskCheckBoxHandle {
  click: () => void;
}

function getIcon(status: SubTaskCheckBoxStatus): ReactNode {
  switch (status) {
    case 'completed':
      return <div className='w-3 h-3 rounded-sm mt-[1px] m-auto bg-gradient-to-t from-[#31DA60] to-[#1A7433]' />;

    default:
      return null;
  }
}

function getSpanClassName(status: SubTaskCheckBoxStatus, passive: boolean, passiveApplied: boolean): string {
  return classNames('rounded-[0.25rem] border border-[#545454] min-w-4 min-h-4 mt-1', {
    'bg-transparent': status === 'completed',

    'bg-[#3B3B3C]': status === 'start',
    // 'bg-[#B5B7C8] !rounded-full !w-[4px] !h-[4px]': passiveApplied,
  });
}

function renderTweetInput(tweetLink: string, setTweetLink: (link: string) => void, submitLink: () => void, isClicked: boolean) {
  return (
    <div className='mt-4'>
      <div className='flex items-center gap-2'>
        <input
          className='w-full h-[36px] border-[1px] border-[#848484] bg-[#2e383a] bg-opacity-50 rounded-md text-white placeholder-gray-500 placeholder-opacity-50 px-2'
          placeholder='Enter url link to approve your activity.'
          value={tweetLink}
          onChange={(e) => setTweetLink(e.target.value)}
        />
        <button
          className={cn('rounded-[8px] h-full font-bold py-1', !tweetLink || isClicked ? 'text-[#6f706f]' : 'text-[#00C9E4]')}
          disabled={!tweetLink || isClicked}
          onClick={submitLink}
          // loading={loading}
        >
          Verify
        </button>
      </div>
    </div>
  );
}

const SubTaskCheckBox = forwardRef<SubTaskCheckBoxHandle, SubTaskCheckBoxProps>(
  (
    {
      status,
      description,
      onClick,
      info,
      passive,
      isTweet = false,
      tweetLink,
      actionId,
      setTweetLink,
      isLocked,
      count,
      userCount,
      amountType,
      amount,
      userAmount,
      name,
    },
    ref,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { isTweetTaskVerified } = useStore();

    useImperativeHandle(ref, () => ({
      click: () => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      },
    }));
    const icon = getIcon(status);
    const clickHandler = () => {
      if (!isLocked) {
        if (status === 'start' || status === 'pending' || status === 'not_verified') {
          onClick?.();
        }
      }
    };

    const passiveApplied = passive && !(status === 'completed' || status === 'pending');
    // const renderIcon = () => {
    //   switch (status) {
    //     case 'completed':
    //       return <div className='w-4 h-4 bg-gradient-to-t from-[#31DA60] to-[#1A7433]' />;
    //     case 'pending':
    //       return <LoadingSvg className='w-full h-full' />;
    //     case 'start':
    //       return null;
    //     default:
    //       return null;
    //   }
    // };
    return (
      <div className='mb-2'>
        {!passive ? (
          <button
            ref={buttonRef}
            className={classNames(
              'flex gap-1.5 items-start',
              isLocked ? 'cursor-not-allowed' : '',
              //  { 'items-center': passiveApplied }
            )}
            onClick={clickHandler}
          >
            {/* <Tooltip disabled={!info} label={info}> */}
            <span className={getSpanClassName(status, passive, passiveApplied)}>{icon}</span>
            {/* </Tooltip> */}
            <div>
              <Typography
                size='s14ToS12'
                className={cn(
                  style.hoverAnimation,
                  `text-start text-[#B5B7C8] flex items-start`,
                  { [style.someOtherClass]: status !== 'completed' }, // Conditionally add another class if needed
                )}
              >
                {description} {name === 'mining' && userAmount !== 0 && amount !== 0 && userAmount.toFixed(2) + '/' + amount.toFixed(2)}
                {/* {userCount > count
                ? '(' + numberSeparator(count) + (amountType !== null && amountType !== undefined ? ' ' + amountType : '') + ')'
                : `(${userCount !== null ? numberSeparator(userCount) : 0} / ${numberSeparator(count)}) ${
                    amountType == null || amountType == undefined ? '' : amountType
                  }`} */}
              </Typography>
            </div>
          </button>
        ) : (
          <div className={classNames('flex gap-1.5 items-start')} onClick={clickHandler}>
            <span className={getSpanClassName(status, passive, passiveApplied)}>{icon}</span>
            <div>
              <Typography size='s14ToS12' className={`text-start  text-[#B5B7C8] flex items-start ${status !== 'completed' && ''}`}>
                {description} {name === 'mining' && userAmount !== 0 && amount !== 0 && userAmount.toFixed(2) + '/' + amount.toFixed(2)}
              </Typography>
            </div>
          </div>
        )}

        {isTweet && renderTweetInput(tweetLink, setTweetLink, clickHandler, isTweetTaskVerified.includes(actionId))}
      </div>
    );
  },
);

// Adding display name for the component
SubTaskCheckBox.displayName = 'SubTaskCheckBox';

export default SubTaskCheckBox;
