/* eslint-disable sonarjs/no-duplicate-string */
import { LockedSvg } from '@/components/Svg';
import CompletedSvg from '@/components/Svg/CompletedSvg';
import WorkingSvg from '@/components/Svg/WorkingSvg';
import YesSirSvg from '@/components/Svg/YesSirSvg';

export const STATE_CONDITIONS = {
  start: {
    border: 'border-[#00F0FF]',
    textColor: 'text-[#00C9E4]',
    bg: 'bg-rgba(10, 80, 84, 0.48)',
    text: 'commandCenter:CENTER_SECTION.achievementSection.button.confirm',
    icon: YesSirSvg,
    hover: 'bg-[#fff]',
  },
  completed: {
    border: 'border-[#31DA60]',
    textColor: 'text-[#31DA60]',
    bg: 'bg-[#31da6128]',
    text: 'commandCenter:CENTER_SECTION.achievementSection.button.complete',
    icon: CompletedSvg,
    hover: 'bg-rgba(10, 80, 84, 0.18)',
  },
  claimable: {
    border: 'border-[#31DA60]',
    textColor: 'text-[#31DA60]',
    bg: 'bg-[#31da6128]',
    text: 'Claimable',
    icon: CompletedSvg,
    hover: 'bg-rgba(10, 80, 84, 0.18)',
  },
  pending: {
    border: 'border-[#FF9900]',
    textColor: 'text-[#FF9900]',
    bg: 'bg-[#ff990022]',
    text: 'commandCenter:CENTER_SECTION.achievementSection.button.process',
    icon: WorkingSvg,
    hover: 'bg-rgba(10, 80, 84, 0.18)',
  },
  lock: {
    border: 'border-[#757575]',
    textColor: 'text-[#FFFFFF]',
    bg: 'bg-[#ff990022]',
    text: 'Locked',
    icon: LockedSvg,
    hover: 'bg-rgba(10, 80, 84, 0.18)',
  },
  pending_verify: {
    border: 'border-[#1AFFFF]',
    textColor: 'text-[#1AFFFF]',
    bg: 'bg-[#1AFFFF]/[0.2]',
    text: 'Processing',
    icon: WorkingSvg,
    hover: 'bg-rgba(10, 80, 84, 0.18)',
  },
  not_verified: {
    border: 'border-[#FF9900]',
    textColor: 'text-[#FF9900]',
    bg: 'bg-[#ff990022]',
    text: 'commandCenter:CENTER_SECTION.achievementSection.button.process',
    icon: WorkingSvg,
    hover: 'bg-rgba(10, 80, 84, 0.18)',
  },
};
