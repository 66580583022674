import { SVGProps } from 'react';

const ArmiesListSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width='13' height='15' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.5965 1.5816H9.90748V1.2954C9.90748 0.791402 9.49767 0.381592 8.99367 0.381592H4.82127C4.31727 0.381592 3.90746 0.791402 3.90746 1.2954V1.5816H2.21846C1.49545 1.5816 0.907471 2.16961 0.907471 2.8926V13.4706C0.907471 14.1936 1.49545 14.7816 2.21846 14.7816H11.5965C12.3195 14.7816 12.9075 14.1936 12.9075 13.4706V2.8926C12.9075 2.16958 12.3195 1.5816 11.5965 1.5816ZM5.10747 1.5816H8.70747V2.78161H5.10747V1.5816ZM9.30746 11.7816H4.50748C4.17628 11.7816 3.90748 11.5128 3.90748 11.1816C3.90748 10.8504 4.17628 10.5816 4.50748 10.5816H9.30749C9.63869 10.5816 9.90748 10.8504 9.90748 11.1816C9.90748 11.5128 9.63867 11.7816 9.30746 11.7816ZM9.30746 9.38161H4.50748C4.17628 9.38161 3.90748 9.11281 3.90748 8.78161C3.90748 8.45041 4.17628 8.18162 4.50748 8.18162H9.30749C9.63869 8.18162 9.90748 8.45041 9.90748 8.78161C9.90748 9.11281 9.63867 9.38161 9.30746 9.38161ZM9.30746 6.98161H4.50748C4.17628 6.98161 3.90748 6.71282 3.90748 6.38162C3.90748 6.05042 4.17628 5.7816 4.50748 5.7816H9.30749C9.63869 5.7816 9.90748 6.05039 9.90748 6.38159C9.90748 6.71279 9.63867 6.98161 9.30746 6.98161Z'
      fill='#00D0AB'
    />
  </svg>
);
export default ArmiesListSvg;
