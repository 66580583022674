import { toast } from 'react-toastify';
import api from '@/api/services/achievements';
import { ITaskActionDataType } from '@/types/Achievements';

const useDiscord = (item: ITaskActionDataType & { referal_user_id?: string; achievementId?: string }, isVerified: boolean) => {
  const REDIRECT_URI = 'http://localhost:3000/api/auth/discord/callback';
  const YOUR_CLIENT_ID = '1202528459734261781';
  // const YOUR_CLIENT_ID = `1234446681098162186`;
  const link = `https://discord.com/api/oauth2/authorize?client_id=${YOUR_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=identify`;

  const startDiscordFlow = async () => {
    if (!isVerified) {
      window.open(link);
      return;
    }

    try {
      await api.doneTask({ id: item?.id, data: item?.additional_data, referal_user_id: item?.referal_user_id, achievement_id: item.achievementId });
    } catch (error) {
      toast.error('Invalid Referral Code. Please check your referral code and try again.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#5a0000',
          color: 'white',
        },
      });
      console.error('Error processing the tweet task:', error);
    }
  };

  return { startDiscordFlow };
};

export default useDiscord;
