import CustomSkeleton from '@/components/Common/CustomSkeleton';

const TaskStatisticsLoading = () => (
  <>
    <CustomSkeleton className='w-full' height={14} width='70%' radius='xl' />
    <CustomSkeleton height={14} mt={12} radius='xl' width='full' />
    <CustomSkeleton height={14} mt={12} radius='xl' width='70%' />
    <CustomSkeleton height={14} mt={12} radius='xl' width='full' />
    <CustomSkeleton height={14} mt={12} radius='xl' width='70%' />
    <CustomSkeleton height={14} mt={12} radius='xl' width='full' />
    <CustomSkeleton height={14} mt={12} radius='xl' width='70%' />
  </>
);

export default TaskStatisticsLoading;
