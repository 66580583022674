import * as React from 'react';

const TimeLeftSvgTwo = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={props.width ? props.width : "16"} height={props.height ? props.height : "16"} fill='none' {...props}>
    <g stroke={props.color ? props.color : "#31DA60"} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.01} clipPath='url(#a)'>
      <path d='M7.746 13.954a6.315 6.315 0 1 0 0-12.629 6.315 6.315 0 0 0 0 12.63Z' />
      <path d='M7.746 3.85v3.79l2.526 1.263' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.168.062h15.155v15.155H.168z' />
      </clipPath>
    </defs>
  </svg>
);
export default TimeLeftSvgTwo;
