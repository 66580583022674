import * as React from 'react';

const FrameSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={props.width ? props.width : "20"} height={props.height ? props.height : "20"} fill='none' {...props}>
    <path
      stroke={props.color ? props.color : '#9E9E9E'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M5.833 17.5v-1.667A3.333 3.333 0 0 1 9.167 12.5h6.666a3.333 3.333 0 0 1 3.334 3.333V17.5M12.5 9.167a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667ZM.833 17.5v-1.667a3.333 3.333 0 0 1 2.5-3.225M6.667 2.608a3.333 3.333 0 0 0 0 6.459'
    />
  </svg>
);
export default FrameSvg;
