export const allMaterials = [
  {
    Id: 'f5552d60-4d8c-4454-8b9a-e9c1052342ca',
    Name: 'Helium',
    Image: 'Helium_image',
    Description: 'Helium_dist',
    MetaData: 'Helium_meta',
    Abbr: 'He',
    Unit: 'kg',
    Unit_value: 1,
    Color: 'B7E4EA',
    Status: true,
    Icon: 'Element/He.jpg',
    Frequency: 0,
    Phase: 'gas',
    Rarity: 0.000401,
    Index: 1,
  },
  {
    Id: '446f7eee-9419-47ee-b239-8581c11091ed',
    Name: 'Nitrogen',
    Image: 'Nitrogen_image',
    Description: 'Nitrogen_dist',
    MetaData: 'Nitrogen_meta',
    Abbr: 'N',
    Unit: 'kg',
    Unit_value: 1,
    Color: '631E68',
    Status: true,
    Icon: 'Element/N.jpg',
    Frequency: 0,
    Phase: 'gas',
    Rarity: 0.000785,
    Index: 11,
  },
  {
    Id: '9d71a470-3f44-4c31-aad8-53049f074f2f',
    Name: 'Hydrogen',
    Image: 'Hydrogen_image',
    Description: 'Hydrogen_dist',
    MetaData: 'Hydrogen_meta',
    Abbr: 'H',
    Unit: 'kg',
    Unit_value: 1,
    Color: 'B260B2',
    Status: true,
    Icon: 'Element/H.jpg',
    Frequency: 0,
    Phase: 'gas',
    Rarity: 0.000942,
    Index: 118,
  },
  {
    Id: '3cafa18a-1c85-4170-80b5-a8a1f98773c9',
    Name: 'Oxygen',
    Image: 'Oxygen_image',
    Description: 'Oxygen_dist',
    MetaData: 'Oxygen_meta',
    Abbr: 'O',
    Unit: 'kg',
    Unit_value: 1,
    Color: 'EDEDED',
    Status: true,
    Icon: 'Element/O.jpg',
    Frequency: 0,
    Phase: 'gas',
    Rarity: 0.001586,
    Index: 22,
  },
  {
    Id: 'd4b3d7c3-8190-4965-9e0a-1374913e69c1',
    Name: 'Silicon',
    Image: 'Silicon_image',
    Description: 'Silicon_dist',
    MetaData: 'Silicon_meta',
    Abbr: 'Si',
    Unit: 'kg',
    Unit_value: 1,
    Color: '2D4B8E',
    Status: true,
    Icon: 'Element/Si.jpg',
    Frequency: 0.9,
    Phase: 'solid',
    Rarity: 0.002704,
    Index: 32,
  },
  {
    Id: '7185f94b-c6e3-49da-a372-e31e50db432f',
    Name: 'Titanium',
    Image: 'Titanium_image',
    Description: 'Titanium_dist',
    MetaData: 'Titanium_meta',
    Abbr: 'Ti',
    Unit: 'kg',
    Unit_value: 1,
    Color: '3DD6E2',
    Status: true,
    Icon: 'Element/Ti.jpg',
    Frequency: 0.4,
    Phase: 'solid',
    Rarity: 0.004582,
    Index: 43,
  },
  {
    Id: '803ab459-e156-4fd3-b1a9-5aab3bbba4f5',
    Name: 'Calcium',
    Image: 'Calcium_image',
    Description: 'Calcium_dist',
    MetaData: 'Calcium_meta',
    Abbr: 'Ca',
    Unit: 'kg',
    Unit_value: 1,
    Color: 'FFFFE6',
    Status: true,
    Icon: 'Element/Ca.jpg',
    Frequency: 0.8,
    Phase: 'solid',
    Rarity: 0.007354,
    Index: 54,
  },
  {
    Id: 'b6ac4177-82de-47f8-a92f-d5cce9ff3da5',
    Name: 'Magnesium',
    Image: 'Magnesium_image',
    Description: 'Magnesium_dist',
    MetaData: 'Magnesium_meta',
    Abbr: 'Mg',
    Unit: 'kg',
    Unit_value: 1,
    Color: '556AAD',
    Status: true,
    Icon: 'Element/Mg.jpg',
    Frequency: 0.9,
    Phase: 'solid',
    Rarity: 0.01021,
    Index: 64,
  },
  {
    Id: 'd86149a9-02a0-4991-8052-172dd58c7e8c',
    Name: 'Carbon',
    Image: 'Carbon_image',
    Description: 'Carbon_dist',
    MetaData: 'Carbon_meta',
    Abbr: 'C',
    Unit: 'kg',
    Unit_value: 1,
    Color: 'FFFFFF',
    Status: true,
    Icon: 'Element/C.jpg',
    Frequency: 1,
    Phase: 'solid',
    Rarity: 0.013289,
    Index: 75,
  },
  {
    Id: 'f5741139-e86c-44b7-83bd-f443f1f78a56',
    Name: 'Sodium',
    Image: 'Sodium_image',
    Description: 'Sodium_dist',
    MetaData: 'Sodium_meta',
    Abbr: 'Na',
    Unit: 'kg',
    Unit_value: 1,
    Color: '7ACFDD',
    Status: true,
    Icon: 'Element/Na.jpg',
    Frequency: 0.6,
    Phase: 'solid',
    Rarity: 0.015518,
    Index: 107,
  },
  {
    Id: '8fefbbe6-b7d2-4675-90f4-ecb24f260e00',
    Name: 'Aluminium',
    Image: 'Aluminium_image',
    Description: 'Aluminium_dist',
    MetaData: 'Aluminium_meta',
    Abbr: 'Al',
    Unit: 'kg',
    Unit_value: 1,
    Color: '5a5a5a',
    Status: true,
    Icon: 'Element/Al.jpg',
    Frequency: 0.8,
    Phase: 'solid',
    Rarity: 0.016069,
    Index: 86,
  },
  {
    Id: 'ad9d5676-f81a-46bd-9e9d-e74018ca2786',
    Name: 'Iron',
    Image: 'Iron_image',
    Description: 'Iron_dist',
    MetaData: 'Iron_meta',
    Abbr: 'Fe',
    Unit: 'kg',
    Unit_value: 1,
    Color: 'E84E1C',
    Status: true,
    Icon: 'Element/Fe.jpg',
    Frequency: 1,
    Phase: 'solid',
    Rarity: 0.017175,
    Index: 96,
  },
  {
    Id: '00000000-0000-0000-0000-000000000000',
    Name: 'LRT',
    Image: '',
    Description: '',
    MetaData: '',
    Abbr: 'LRT',
    Unit: '',
    Unit_value: 0,
    Color: '',
    Status: false,
    Icon: '',
    Frequency: 0,
    Phase: '',
    Rarity: 0,
    Index: 0,
  },
];
