const TaskArrowDown = (props) => (
  <svg width={11} height={7} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.00037 1.24344L5.50004 5.65646L9.99971 1.24344'
      stroke='#00C9E4'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default TaskArrowDown;
