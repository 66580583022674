import * as React from 'react';

const CompletedSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' viewBox='0 0 20 20' {...props}>
    <path
      fill='#31DA60'
      d='M9.092 16.843a6.842 6.842 0 1 1 4.077-12.34.582.582 0 0 1-.693.934 5.681 5.681 0 1 0 2.221 3.67.59.59 0 0 1 1.086-.397.59.59 0 0 1 .08.216c.051.355.076.715.072 1.074a6.849 6.849 0 0 1-6.843 6.843Z'
    />
    <path
      fill='#31DA60'
      d='M9.82 12.184a.582.582 0 0 1-.41-.172L6.643 9.246a.582.582 0 0 1 .822-.822l2.355 2.353 7.015-7.011a.582.582 0 0 1 .821.82l-7.425 7.426a.582.582 0 0 1-.41.172Z'
    />
  </svg>
);
export default CompletedSvg;
