import classNames from 'classnames';
import Image from 'next/image';
import { Typography } from '@/components/Atoms';
import CustomSkeleton from '@/components/Common/CustomSkeleton';
import ArmiesListSvg from '@/components/Svg/TaskCenter/ScrollSvg';

type IProps =
  | { loading: true }
  | {
      img?: string;
      title: string;
      value: {
        // current: number;
        max: number;
      };
      loading?: false;
      onClick?: () => void;
    };

const ArmieItem = (props: IProps) => (
  <button
    className={classNames(
      'flex items-center justify-between w-full transition-transform duration-300 ease-in-out transform hover:scale-102 lg:hover:scale-105 hover:shadow-lg hover:border hover:border-[#00D0AB] hover:rounded-lg', // Added hover effects here
      props.loading && 'cursor-not-allowed', // Disable hover effects when loading
    )}
    onClick={props.loading === true ? undefined : props.onClick}
  >
    <div className='flex gap-1 items-center'>
      {/* Image */}
      <CustomSkeleton visible={props.loading === true}>
        <div className={classNames('aspect-[33/24] h-6 rounded-md relative', props.loading && 'bg-black')}>
          {props.loading === true ? null : <Image layout='fill' className='object-contain' src={props.img} alt='Badge image' />}
        </div>
      </CustomSkeleton>

      {/* Title */}
      <CustomSkeleton visible={props.loading === true}>
        <Typography size='s12ToS12'>{props.loading === true ? 'title' : props.title}</Typography>
      </CustomSkeleton>
    </div>

    {/* Value */}
    <div className='flex items-center gap-1 text-[#00D0AB]'>
      <CustomSkeleton visible={props.loading === true}>
        <Typography size='s12ToS12'>{props.loading === true ? '10' : `${props.value.max}`}</Typography>
      </CustomSkeleton>
      <ArmiesListSvg className='h-3' />
    </div>
  </button>
);

export default ArmieItem;
