import React, { forwardRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import api from '@/api/services/achievements';
import { useStore } from '@/zustand/store/store';
import { IProps, useActionHandler } from '../ActionDetailItem/actionHandler';
import ConfirmSection, { ConfirmSectionIProps } from '../ConfirmSection';
// eslint-disable-next-line import/extensions
import SubTaskCheckBox, { SubTaskCheckBoxHandle, SubTaskCheckBoxStatus } from './SubTaskCheckBox';
import { toast } from 'react-toastify';

function extractTweetId(url: string) {
  const regex = /status\/(\d+)/;

  const match = url?.match(regex);

  return match ? match[1] : null;
}

type SubTaskCheckItemProps = IProps & {
  onClick?: (activityOnClick: () => Promise<void>) => void;
  achievementId: string;
  confirmProps: ConfirmSectionIProps;
};

const SubTaskCheckItem = forwardRef<SubTaskCheckBoxHandle, SubTaskCheckItemProps>(({ achievementId, confirmProps, ...props }, ref) => {
  const [tweetLink, setTweetLink] = useState('');
  const tweetId = extractTweetId(tweetLink);
  const { setOnBoardingData, onBoardingData } = useStore();
  const { activityOnClick } = useActionHandler({ achievementId, tweetId, ...props });
  const router = useRouter();
  const isTweet = props?.action_type?.Name === 'twitter_tweet';
  const [isRequestPending, setIsRequestPending] = useState(false); // Tracks if a request is in progress

  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(false);

  useEffect(() => {
    if (props.state !== 'pending') {
      setIsVerifyButtonDisabled(false);
    }
  }, [props.state]);

  let status: SubTaskCheckBoxStatus = 'start';
  let info: string;
  if (props.state === 'completed') {
    status = 'completed';
  } else if (props.state === 'pending') {
    status = 'pending';
    info = 'It will take at most 20min';
  }

  // This is used to change the query parameters in the URL.
  const updateQueryParams = (newParams) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, ...newParams },
      },
      undefined,
      { shallow: true },
    );
  };

  const clickHandler = () => {
    if (props?.onClick) {
      return props?.onClick(activityOnClick);
    }
    return activityOnClick();
  };

  const confirmClickHandler = () => {
    if (isRequestPending || isVerifyButtonDisabled) return;

    setOnBoardingData({ ...onBoardingData, isShowHint: false });

    if (props.state === 'start') {
      return clickHandler();
    }

    if (props.state === 'pending' || props.state === 'not_verified') {
      if (router.query.isonboarding === 'step-three') {
        updateQueryParams({ isonboarding: 'step-four' });
      }
      setIsRequestPending(true);
      setIsVerifyButtonDisabled(true); // Disable the verify button

      api
        .verifyTask({
          achievement_id: achievementId,
          action_id: props.id,
        })
        .catch((error) => {
          console.error('Error verifying task:', error);
          toast.error('An error occurred while verifying the task. Please try again later.', {
            position: 'bottom-center',
            autoClose: 4000,
            closeOnClick: true,
            theme: 'dark',
          });
        })
    }

    if (props.state === 'completed') {
      // No action needed
    }
  };

  return (
    <div className='flex gap-2 mt-2 items-start justify-between'>
      <SubTaskCheckBox
        ref={ref}
        onClick={props.isLocked ? () => {} : clickHandler}
        description={props?.description}
        // @ts-ignore
        status={props.state}
        info={info}
        isTweet={isTweet}
        tweetLink={tweetLink}
        actionId={props.id}
        setTweetLink={setTweetLink}
        isLocked={confirmProps.isLocked}
        count={props.count}
        userCount={props.user_count_progress}
        amount={props.amount}
        userAmount={props.user_amount}
        name={props.action_type.Name}
      />

      <div className='flex justify-end items-center' id={`${props.action_type.Name}-button`}>
        <ConfirmSection {...confirmProps} onClick={confirmClickHandler} disabled={isVerifyButtonDisabled} />
      </div>
    </div>
  );
});

SubTaskCheckItem.displayName = 'SubTaskCheckItem';

export default SubTaskCheckItem;
