import { Typography } from '@/components/Atoms';
import { ITaskActionDataType } from '@/types/Achievements';
import style from './ActionDetail.module.scss';

type IProps = ITaskActionDataType;

const ActionDetailItem = ({ ...taskDetails }: IProps) => {
  const tweetText = taskDetails?.additional_data && JSON.parse(taskDetails?.additional_data)?.content;
  const encodedTweetText = encodeURIComponent(tweetText);

  return (
    <div className={style.textDetailBox}>
      <div key={taskDetails.id} dangerouslySetInnerHTML={{ __html: taskDetails.content }} />
      {taskDetails.action_type.Name === 'twitter_tweet' && (
        <div className='flex flex-col gap-2'>
          <Typography size='s16ToS12' className='text-white'>
            To complete this task, share
            <span
              onClick={() => window.open(`https://x.com/intent/tweet?text=${encodedTweetText}`)}
              className='text-[#00D3FF] underline cursor-pointer'
            >
              {' '}
              this tweet{' '}
            </span>
            on X. Once posted, copy the post URl into the input field below and click 'Verify'.
          </Typography>
          <Typography size='s16ToS12' className='text-[#767881] italic'>
            * Remember, the url cannot be changed after submission.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ActionDetailItem;
