import * as React from 'react';

const WorkingSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={17} fill='none' {...props}>
    <path
      stroke='#F90'
      strokeWidth={0.81}
      d='M.72 1.148h14.585m-11.933 0v3.315c0 1.989 3.315 2.145 3.315 3.978 0 1.833-3.315 1.989-3.315 3.977v3.315m9.281-14.585v3.315c0 1.989-3.314 2.145-3.314 3.978 0 1.833 3.314 1.989 3.314 3.977v3.315m-11.933 0h14.585M6.687 3.469h2.652v.994c0 .663-1.326 1.326-1.326 1.326s-1.326-.663-1.326-1.326V3.47ZM5.36 14.407c0-1.326 2.652-2.652 2.652-2.652s2.652 1.326 2.652 2.652v1.326H5.36v-1.326Z'
    />
  </svg>
);
export default WorkingSvg;
